import $ from "../util/dom.js";
import user from "../user.js";
import translations from "../translations.js";
import api from "../api.js";
import Settings from "../settings.js";

let Login = (()=>{
    let me = {};

    me.render = container=>{
        return new Promise((next)=>{
            container.innerHTML = "";
            document.body.classList.add("login");
            let panel,loginInput, passwordInput, button, title;

            container.appendChild(panel=$(".login",
                title = $(".title","Kufatilia v" + Settings.version),
                $(".inputbox",
                    $("label",translations.username),
                    loginInput=$("input",{"type":"text",onkeyup:(e)=>{
                            if (e.keyCode===13)button.onClick();
                        }})),
                $(".inputbox",
                    $("label",translations.password),
                    passwordInput = $("input",{"type":"password",onkeyup:(e)=>{
                            if (e.keyCode===13)button.onClick();
                    }})
                ),
                $(".textlink",{onClick:(e)=>{
                        e.stopPropagation();
                        renderPasswordResetForm(container,next);
                    }},translations.forgotPassword
                ),
                button = $("button",
                    {onClick:()=>{
                            let login = validate(loginInput);
                            let password = validate(passwordInput);

                            if (login && password){
                                button.classList.add("loading");
                                user.login(login,password).then(result=>{
                                    button.classList.remove("loading");
                                    if (!result.success){
                                        title.innerHTML = translations.loginFailed;
                                        shake(panel);
                                    }else{
                                        if (result.needsPasswordReset){
                                            renderPasswordReset(container,next);
                                        }else{
                                            let userData = user.getCurrentUser();
                                            if (userData && userData.id && !userData.active){
                                                title.innerHTML = translations.accountIsDisabled;
                                                shake(panel);
                                                return;
                                            }
                                            container.innerHTML = "";
                                            document.body.classList.remove("login");
                                            next();
                                        }
                                    }
                                }).catch(()=>{
                                    button.classList.remove("loading");
                                });
                            }
                        }},
                    $(".spinner"),translations.login)
            ));
        });
    }

    function renderPasswordReset(container,next){
        container.innerHTML = "";
        document.body.classList.add("passwordreset");

        let currentUser = user.getCurrentUser();
        let pass1, pass2, title;

        container.appendChild($(".login",
            $("h2",translations.hello + " " + currentUser.fullname),
            $(".intro",translations.firstLogin),
            title = $(".intro",translations.pleaseChangePassword),
            $(".inputbox",$("label",translations.newPassword),pass1 = $("input",{"type":"password"})),
            $(".inputbox",$("label",translations.confirmPassword),pass2 = $("input",{"type":"password"})),
            $("button",{
                onClick:()=>{
                    let p1 = validate(pass1);
                    let p2 = validate(pass2);
                    if (p1!==p2){
                        title.innerHTML = translations.passwordsDontMatch;
                        validate(pass1,true);
                        validate(pass2,true);
                    }
                    if (p1 && p2 && p1===p2){
                        api.changePassword(p1).then(()=>{
                            container.innerHTML = "";
                            document.body.classList.remove("passwordreset");
                            document.body.classList.remove("login");
                            next();
                        });
                    }
                }
            },translations.changePassword)
            ));
    }

    function renderPasswordResetForm(container,next){
        let title,inputBox,emailInput,button
        container.innerHTML = "";
        container.appendChild($(".login",
            title = $(".title",translations.enterEmail),
            inputBox = $(".inputbox",$("label",translations.username),emailInput=$("input")),
            button = $("button",
                {onClick:()=>{
                        let login = validate(emailInput);
                        if (login && (!login.includes("@") || !login.includes("."))){
                            validate(emailInput,true);
                            login = null;
                        }

                        if (login){
                            button.classList.add("loading");
                            api.resetPassword(login).then(result=>{
                                button.style.display = "none";
                                inputBox.style.display = "none";
                                title.innerHTML = translations.enterEmailThankYou;
                            });
                        }
                    }},
                $(".spinner"),translations.continue)
        ));
    }

    function validate(input,iserror){
        let value = input.value;
        if (!value || iserror){
            let parent = input.closest(".inputbox");
            parent.classList.remove("error");
            setTimeout(()=>{
                parent.classList.add("error");
            },10);
        }
        return value;
    }

    function shake(elm){
        elm.classList.remove("shake");
        setTimeout(()=>{
            elm.classList.add("shake");
        },10);
    }


    return me;
})();
export default Login;