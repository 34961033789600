
let Settings = (()=>{
    let me = {
        version: "0.0.12",
        skipLogin: false
    };

    me.getTheme = ()=>{
        return localStorage.getItem("theme") || "dark";
    }

    me.setTheme = (theme)=>{
        localStorage.setItem("theme",theme);
    }

    me.getLanguage = ()=>{
        return localStorage.getItem("language") || "fr";
    }

    me.setLanguage = (language)=>{
        localStorage.setItem("language",language);
    }


    return me;
})();
export default Settings;