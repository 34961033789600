export function formatDate(date){
    let sDate = date;
    if (typeof date === "string") date = new Date(date);
    if (typeof date === "number") date = new Date(date);

    let month = date.getMonth()+1;

    if (isNaN(month)){
        if (sDate.indexOf("/")>0){
            let parts = sDate.split("/");
            if (parts.length===3){
                date = new Date(parts[2] + "/" + parts[1] + "/" + parts[0]);
                month = date.getMonth()+1;
            }
        }
    }

    if (isNaN(month)){
        console.error("invalid date",sDate);
        return sDate;
    }

    let day = date.getDate();
    if (day<10) day = "0"+day;

    if (month<10) month = "0"+month;
    return date.getFullYear() + "/" + month + "/" + day;
}

export function cleanString(str){
    if (str) return str.trim().replace(/[^a-zA-Z0-9]/g,"_").toLowerCase();
    return "";
}

export function isUUID(str){
    if (typeof str !== "string") return false;
    return !!str.match(/^[a-f0-9]{8}(-[a-f0-9]{4}){4}[a-f0-9]{8}$/i);
}