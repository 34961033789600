import translations from "./translations.js";
import {formatDate} from "./util.js";

let Data = (()=>{
    let me = {};


    let regions={
        "Haut-Uele":["Dungu","Faradje","Niangara","Rungu","Wamba","Watsa"],
        "Ituri":["Aru","Djugu","Irumu","Mahagi","Mambasa"],
        "Lualaba":["Dilolo","Kapanga","Lubudi","Mutshatsha","Sandoa","Ville de Kolwezi"],
        "Maniema":["Kabambare","Kailo","Kasongo","Kibombo","Kindu","Lubutu","Pangi","Punia"],
        "Nord-Kivu":["Beni","Lubero","Masisi","Nyiragongo","Rutshuru","Walikale"],
        "Sud-Kivu":["Fizi","Idjwi","Kabare","Kalehe","Mwenga","Shabunda","Uvira","Walungu"],
        "Tanganyika":["Kabalo","Kalemie","Kongolo","Manono","Moba","Nyunzu"],
        "Autre province":["Autre territoire"]
    }

    let categories={
        "Environmental issue":["Mercury use","Water pollution","Air pollution","Soil pollution","Deforestation","Poaching","Other"],
        "Roadblock":["Abuse of power","Illegal taxation","Violence","Bribery","Other"],
        "Child labor":["Surface digging","Crushing or panning","Transporting","Mercury","Underground digging","Operating machines","Dont know","Other"],
        "Violence/Theft":["Armed attack","Sexual violence","Theft and pillage","Kidnapping","Torture, degrading treatment","Forced labour","Other"],
        "Accident":["Pit collapse","Injury of miners","Traffic accident","Equipment failure","Use of explosives","Flooding","Dont know","Other"],
        "Corruption/Fraud":["Non-declaration of production","Illegal taxation","Mixing origin of minerals","Selling of iTSCi tags","Bribery","Other","Dont know"],
        industrial_conflict:["industrial_conflict"],
        "Other":["Other"],
    }

    let territoireLocations = {
        Rungu:{latitude:2.668,longitude:27.6489255408781},
        Niangara:{latitude:3.512,longitude:27.9016848247627},
        Dungu:{latitude:4.072,longitude:28.4185767921046},
        Wamba:{latitude:2.104,longitude:27.83610526906},
        Faradje:{latitude:3.54,longitude:29.9776464583297},
        Watsa:{latitude:2.737,longitude:29.2605691085919},
        Djugu:{latitude:1.944,longitude:30.3243837352359},
        Mahagi:{latitude:2.354,longitude:30.7133206376299},
        Aru:{latitude:3.067,longitude:30.5332204968841},
        Irumu:{latitude:1.228,longitude:29.9002899111641},
        Mambasa:{latitude:1.494,longitude:28.7779410904815},
        Mutshatsha:{latitude:-10.77,longitude:25.0570728905394},
        Lubudi:{latitude:-10.225,longitude:26.1370602169313},
        "Ville de Kolwezi":{latitude:-10.71484,longitude:25.4667},
        Kapanga:{latitude:-8.4667,longitude:22.4},
        Dilolo:{latitude:-10.6667,longitude:22.3333},
        Sandoa:{latitude:-8.95,longitude:23.95},
        Kindu:{latitude:-2.97,longitude:25.9124992832093},
        Lubutu:{latitude:-0.778,longitude:26.8099739403865},
        Punia:{latitude:-1.712,longitude:26.7807144670867},
        Kabambare:{latitude:-4.541,longitude:27.721021737493},
        Kasongo:{latitude:-4.361,longitude:26.4623205320502},
        Kailo:{latitude:-2.507,longitude:25.6815707364452},
        Pangi:{latitude:-3.188,longitude:26.5655252235655},
        Kibombo:{latitude:-4.075,longitude:25.4277204822976},
        Walikale:{latitude:-1.105,longitude:28.1661663936745},
        Masisi:{latitude:-1.41,longitude:28.8490522552961},
        Rutshuru:{latitude:-1.031,longitude:29.3458253465677},
        Beni:{latitude:0.475,longitude:29.4554831118829},
        Lubero:{latitude:0.036,longitude:28.7485144421101},
        Nyiragongo:{latitude:-1.542,longitude:29.2752332850615},
        Idjwi:{latitude:-2.121,longitude:29.0617094461649},
        Mwenga:{latitude:-3.329,longitude:28.369724436641},
        Shabunda:{latitude:-2.911,longitude:27.5788296464442},
        Fizi:{latitude:-4.24,longitude:28.7160641247728},
        Kalehe:{latitude:-2.074,longitude:28.5993645772594},
        Walungu:{latitude:-2.712,longitude:28.6429882785182},
        Uvira:{latitude:-3.201,longitude:29.0058420033867},
        Kabare:{latitude:-2.424,longitude:28.6403535824063},
        Kongolo:{latitude:-5.366,longitude:26.851731601852},
        Nyunzu:{latitude:-5.727,longitude:27.9358543607606},
        Kalemie:{latitude:-6.007,longitude:28.8233045311648},
        Kabalo:{latitude:-6.256,longitude:26.8903232123947},
        Moba:{latitude:-7.562,longitude:29.5491493249578},
        Manono:{latitude:-7.214,longitude:27.5709788679189}
    }


    me.incidentsSMS={
        profile: "incidents_external",
        fields: [
            /*{label: "id", key: "id", type: "text", isPrimary: true, size: 40}, */
            {label: "report_id", key: "report_id", type: "hidden", isPrimary: true},
            {label: "coordinates", key: "coordinates", type: "map"},
            {label: translations.date, key: "report_date", type: "text", isPrimary: true, format: "date"},
            {label: translations.status, key: "platform_status", type: "text", isPrimary: true, enumFilter: true},
            {label: translations.localisation, key: "platform_location", type: "text", isPrimary: true, size:250},
            {label: translations.category, key: "platform_category", type: "text", isPrimary: true, enumFilter: true, translate:true},
            {label: translations.assigned, key: "assigned", type: "fk", target:"organisations.name", isPrimary: true},
            {label: translations.archived, key: "archived", type: "boolean"},
            {label: translations.feedback, key: "feedback", type: "textarea"},
        ],
        export:true
    }

    me.incidentsAssigned={
        profile: "incidents_assigned",
        fields: [
            {label: "report_id", key: "report_id", keyAlt: "id", type: "hidden", isPrimary: true, format:"id"},
            {label: translations.coordinates, key: "coordinates", type: "map",default: "-4,25"},
            {label: translations.date, key: "date", type: "text", isPrimary: true, isRequired: true, format: "date",default: ()=>formatDate(new Date())},
            {label: translations.title, key: "title", type: "text", isPrimary: true, isRequired: true},
            {label: translations.description, key: "description", type: "textarea"},
            {label: translations.status, key: "status", type: "select", values:["Assigné","En cours de suivi","Résolu", "Non résolu", "Persistant", "Non-Suivi"], isPrimary: true, enumFilter: true, hideOnEdit: true},
            {label: translations.category, key: "category", type: "select", values: Object.keys(categories),child:"subcategory",childValues:categories,isPrimary: true,translate:true,enumFilter: true, isRequired: true},
            {label: translations.subCategory, key: "subcategory", type: "select" , values:[], enumFilter: true, isRequired: true},
            {label: translations.province, key: "province", type: "select", values: Object.keys(regions),child:"territoire",childValues:regions,isPrimary: true,enumFilter: true, isRequired: true},
            {label: translations.territory, key: "territoire", master:"province",type: "select", values:[], isPrimary: true, isRequired: true},
            {label: translations.chiefdomSector, key: "sector", type: "text"},
            {label: translations.groupQuarter, key: "group", type: "text"},
            {label: translations.miningSite, key: "miningsite", type: "text"},
            {label: "pCode", key: "pcode", type: "text", scope:"owner","allowEmpty":true},
            {label: translations.isIncidentCLS, key: "isCLS", type: "boolean"},
            {label: translations.lastModified, key: "lastModified", type: "hidden", isPrimary: true, format: "date"},
            {label: translations.assigned, key: "assigned", type: "fk", target:"organisations.name", isPrimary: true, hideOnEdit: true},
        ],
        export:true,
        canAdd: true
    }

    me.incidentsInfo={
        profile: "incidents_info",
        fields: [
            {label: "report_id", key: "report_id", type: "hidden", isPrimary: true},
            {label: translations.date, key: "date", format: "date", type: "text", isPrimary: true},
            {label: translations.title, key: "title", type: "text"},
            {label: translations.description, key: "description", type: "textarea"},
            {label: translations.status, key: "status", type: "select", values:["Assigné","En cours de suivi","Résolu", "Non résolu", "Persistant", "Non-Suivi"], enumFilter: true, hideOnEdit: true},
            {label: translations.category, key: "category", type: "select", values: Object.keys(categories),child:"subcategory",childValues:categories,enumFilter: true,translate:true},
            {label: translations.subCategory, key: "subcategory", type: "select" , values:[], isPrimary: true, enumFilter: true,translate:true},
            {label: translations.province, key: "province", type: "select", values: Object.keys(regions),child:"territoire",childValues:regions,isPrimary: true},
            {label: translations.territory, key: "territoire", master:"province",type: "select", values:[]},
            {label: translations.chiefdomSector, key: "sector", type: "text"},
            {label: translations.groupQuarter, key: "group", type: "text"},
            {label: translations.miningSite, key: "miningsite", type: "text"},
            {label: "pCode", key: "pcode", type: "text", scope:"owner","allowEmpty":true},
            {label: translations.isIncidentCLS, key: "isCLS", type: "boolean"},
            {label: translations.lastModified, key: "lastModified", type: "hidden", isPrimary: true}
        ],
    }

    me.users={
        profile: "users",
        name: "Utilisateur",
        fields: [
            {label: "id", key: "id", type: "hidden"},
            {label: translations.username, key: "username", type: "text", isPrimary: true},
            {label: translations.fullname, key: "fullname", type: "text", isPrimary: true},
            {label: translations.email, key: "email", type: "text", isPrimary: true},
            {label: translations.initial, key: "temporaryPassword", type: "password", default: '***' },
            {label: translations.role, key: "role", type: "select", values:[
                    {label:translations.user,value:"user"},
                    {label:translations.admin,value:"admin"},
                    {label:translations.owner,value:"owner"},
                ], isPrimary: true,translate:true},
            {label: translations.organisation, key: "organisation", type: "fk", target:"organisations.name",isPrimary: true},
            {label: translations.preferedLanguage, key: "language", type: "select" , values:["fr","en"]},
            {label: translations.active, key: "active", type: "boolean", default: true, isPrimary: true},
            {label: translations.lastModified, key: "lastModified", type: "hidden", isPrimary: true, format: "date"},
        ],
        export:true,
        canAdd: true,
        canDelete: true,
    }

    me.organisations={
        profile: "organisations",
        name: "Organisation",
        fields: [
            {label: "id", key: "id", type: "hidden"},
            {label: translations.shortName, key: "name", type: "text", isPrimary: true},
            {label: translations.fullName, key: "fullname", type: "text", isPrimary: true},
        ],
        export:true,
        canAdd: true,
        canDelete: true,
    }

    return me;
})();
export default Data;