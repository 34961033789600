import $ from "../util/dom.js"

let Chart = (()=>{

    let me = {};

    me.render = (container,config)=>{

        let chart,legend;
        let total = 0;
        config.items.forEach(item=>{
            total += item.value;
        })

        config.items.forEach(item=>{
            item.pct = Math.round(item.value/total*100);
        })


        container.appendChild($(".chart",
            $(".front",$("b",total),$("small",config.title)),
            chart = $(".piechart"),
            legend = $(".legend")
        ));


        var start = 0;
        var max = config.items.length-1;
        config.items.forEach((piece,index) => {
            var angle = piece.pct*1.8;
            var end = start+angle;
            if (end>180) end = 180;
            var revIndex = max-index;
            var p = generatePie(start,end,revIndex*200,piece.color,piece.action);
            p.style.zIndex = index;
            p.id="pie" + index;
            start = end;

            var item = generateLegentItem(piece.label,piece.color,index*200,piece.value,piece.pct,piece.action);
            item.id = "legend" + index;

            item.onmouseover = function(){
                p.classList.add("active");
            };
            item.onmouseout = function(){
                p.classList.remove("active");
            };
            p.onmouseover = function(){
                item.classList.add("active");
            }
            p.onmouseout = function(){
                item.classList.remove("active");
            }

            chart.appendChild(p);
            legend.appendChild(item);
        });
    }

    function generatePie(start,end,delay,color,action){
        var pie = document.createElement("div");
        pie.className = "pie";

        var lower = document.createElement("div");
        lower.className = "lower";
        lower.style.backgroundColor = color;
        if (action) lower.onclick = action;


        var upper = document.createElement("div");
        upper.className = "upper";

        setTimeout(() => {
            upper.style.transform = 'rotate(' + end + 'deg)';
        },200+delay);

        setTimeout(() => {
            lower.style.transform = 'rotate(' + start + 'deg)';
        },250+delay);

        pie.appendChild(lower);
        pie.appendChild(upper);

        return pie;
    }

    function rotatePie(elm,start,end,delay){
        var lower = elm.querySelector(".lower");
        var upper = elm.querySelector(".upper");

        setTimeout(() => {
            upper.style.transform = 'rotate(' + end + 'deg)';
        },250+delay);

        setTimeout(() => {
            lower.style.transform = 'rotate(' + start + 'deg)';
        },50+delay);
    }

    function generateLegentItem(labelText,color,delay,value,pct,action){
        var item = document.createElement("div");
        item.className = "item";
        if (action) item.onclick = action;

        var label =  document.createElement("div");
        label.className = "label";
        label.innerHTML = '<span class="main">' + labelText + '</span>';
        label.innerHTML += '<span class="val">' + value + '</span>';
        if (pct) label.innerHTML += '<span class="pct">' + pct + '%</span>';

        var dot = document.createElement("div");
        dot.className = "dot";
        dot.style.backgroundColor = color;

        item.appendChild(dot);
        item.appendChild(label);

        setTimeout(() => {
            item.classList.add("visible")
        },500+delay);

        return item;

    }

    return me;

})();

export default Chart;