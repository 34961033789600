import $ from "../util/dom.js";
import page from "../views/page.js";
import api from "../api.js";
import user from "../user.js";
import translations from "../translations.js";
import EventBus from "../lib/eventBus.js";
import Settings from "../settings.js";

let Nav = (()=>{
    let me = {};

    let colors={
        orange:"#b78938",
        green:"#8cce71",
        blue:"#51869d"
    }
    if(Settings.getTheme() === "light"){
        colors={
            orange:"#f5aa43",
            green:"#76ce52",
            blue:"#0aa7dc"
        }
    }

    let menu = [
        {label:translations.incidentsSMS,items:[
                {label:translations.nonAssigned,page:"incidents-sms/non-assignes",key:"nonassigned",color:colors.orange},
                {label:translations.assignedPlural,page:"incidents-sms/assignes",key:"assigned",color:colors.green},
                {label:translations.archivedPlural,page:"incidents-sms/archives",key:"archived",color:colors.blue},
            ],scope:"admin"},
        {label:translations.myIncidents,items:[
                {label:translations.newPlural,page:"mes-incidents/nouveaux",key:"new",color:colors.orange},
                {label:translations.open,page:"mes-incidents/ouvert",key:"working",color:colors.green},
                {label:translations.completed,page:"mes-incidents/completes",key:"complete",color:colors.blue},
            ]},
        {label:translations.allIncidentsCSO,items:[
                {label:translations.newPlural,page:"incidents-cso/nouveaux",key:"pending",color:colors.orange},
                {label:translations.open,page:"incidents-cso/ouvert",key:"open",color:colors.green},
                {label:translations.completed,page:"incidents-cso/completes",key:"closed",color:colors.blue}
            ]},
        {label:translations.users,page:"utilisateurs",scope:"owner"},
        {label:translations.organisations,page:"organisations",scope:"owner"},
        {label:"Information",page:"information"}
    ];

    let itemMap = {};

    me.render = async container=>{
        let menuElm;
        $("nav",{parent:container},
            $(".titlebar",{onClick:()=>{
                page.load("home");
                }},"Kufatilia"),
            menuElm = $(".menu")
        );
        
        menu.forEach(item=>{
            if (item.scope==="admin" && !user.isAdmin()) return;
            if (item.scope==="owner" && !user.isOwner()) return;
            let menuitem = $(".menuitem",{parent:menuElm,onClick:(e)=>{
                let openItems = menuElm.querySelectorAll(".menuitem.open");
                openItems.forEach(item=>{
                    if (item===menuitem) return;
                    item.classList.remove("open");
                });
                let triggerLoad = true;
                if (item.items){
                    if (menuitem.classList.contains("open")) triggerLoad = false;
                    menuitem.classList.toggle("open");
                }
                if (triggerLoad){
                    if (item.page) page.load(item.page);
                    if (item.action) item.action(e);
                }
            }},item.label,item.key?$(".badge"):null);
            item.element = menuitem;

            if (item.items){
                let submenu = $(".submenu",{parent:menuitem});
                item.items.forEach(subitem=>{
                    if (subitem.type==="title"){
                        subitem.element = $(".menutitle",{parent:submenu},subitem.label);
                        return;
                    }

                    subitem.element = $(".menuitem",{parent:submenu,onClick:()=>{
                        page.load(subitem.page);
                    }},subitem.label,subitem.key?$(".badge"):null,subitem.color?$(".dot",{style:{backgroundColor:subitem.color}}):null);

                    if (subitem.key){
                        itemMap[subitem.key] = subitem.element;
                    }
                });
            }
        });
    }

    me.sync = pageName=>{

        let item = menu.find(item=>item.page==pageName);
        if (item){
            let openItems = document.body.querySelectorAll(".menuitem.open");
            openItems.forEach(item=>{
                item.classList.remove("open");
            });
            if (item.element) item.element.classList.add("open");
        }else{
            let parentItem = null;
            menu.forEach(item=>{
                if (item.items){
                    item.items.forEach(subitem=>{
                        if (subitem.element) subitem.element.classList.remove("active");
                        if (subitem.page===pageName){
                            parentItem = item;
                            if (subitem.element) subitem.element.classList.add("active");
                        }else{

                        }
                    })
                    //let subItem = item.items.find(subitem=>subitem.page==pageName);
                    //if (subItem){
                    //    parentItem = item;
                    //}
                }
            });
            if (parentItem){
                let openItems = document.body.querySelectorAll(".menuitem.open");
                openItems.forEach(item=>{
                    item.classList.remove("open");
                });
                if (parentItem.element) parentItem.element.classList.add("open");
            }
        }

    }

    me.updateStats = async (forceRefresh)=>{
        let userData = user.getCurrentUser();
        let orgId = userData?userData.organisation:"";
        let stats = await api.getStats(orgId,forceRefresh);

        function setBadge(elm,count){
            if (elm){
                elm.innerHTML = count;
                if (count>0) elm.classList.add("active");
                else elm.classList.remove("active");
            }
        }

        menu.forEach(item=>{
            if (item.key){
                let value = stats[item.key];
                if (typeof value === "number"){
                    setBadge(item.element.querySelector(".badge"),value);
                }
            }
            if (item.items){
                item.items.forEach(subitem=>{
                    if (subitem.key){
                        let value = stats[subitem.key];
                        if (typeof value === "number" && subitem.element){
                            setBadge(subitem.element.querySelector(".badge"),value);
                        }
                    }
                });
            }
        });

    }

    me.animateUpdate = (from,to,content)=>{
        let fromElm = itemMap[from];
        let toElm = itemMap[to];

        if (fromElm && toElm && isVisible(fromElm) && isVisible(toElm)){
            let fromRect = fromElm.getBoundingClientRect();
            let toRect = toElm.getBoundingClientRect();

            let dot = $(".movedot",{style:{
                top:fromRect.top + "px",
                left:fromRect.width + "px",
            }},content);

            setTimeout(()=>{
               dot.classList.add("active");
               dot.classList.add("move");
               let targetY = fromRect.top + Math.round((toRect.top - fromRect.top) / 2);
               dot.style.top = targetY + "px";
            },20)

            setTimeout(()=>{
                dot.style.top = toRect.top + "px";
                dot.classList.remove("move");
            },500)

            setTimeout(()=>{
                dot.classList.remove("active");
            },700);

            setTimeout(()=>{
                document.body.removeChild(dot);
            },1200);

            document.body.appendChild(dot);

        }

    }

    function isVisible(elm){
        let parent = elm.closest(".submenu");
        if (parent) parent = parent.closest(".menuitem");
        if (!parent) return true;
        return parent.classList.contains("open");

    }

    EventBus.on("dataChanged",me.updateStats);

    window.u = async function(){
        me.animateUpdate("nonassigned","assigned",1);
    }

    return me;
})();
export default Nav;