let Translations = (()=>{
    let en = {
        users: "Users",
        reports: "Reports",
        settings: "Settings",
        logout: "Logout",
        login: "Login",
        password: "Password",
        username: "Username",
        email: "E-mail",
        fullname: "Full name",
        role: "Role",
        organisation: "Organisation",
        active: "Active",
        initial: "Initial password",
        save: "Save",
        cancel: "Cancel",
        add: "Add",
        edit: "Edit",
        delete: "Delete",
        deleted: "Deleted",
        deleteReason: "Please enter the reason for deleting this item.",
        new: "New",
        newPlural: "New",
        search: "Search",
        export: "Export",
        import: "Import",
        noResults: "No results for this filter",
        noDataToDisplay: "No data to display",
        no: "No",
        yes: "Yes",
        preferedLanguage: "Prefered language",
        name: "Name",
        fullName: "Full name",
        shortName: "Abbreviation",
        noData: "No data",
        deleteItemQuestion: "Are you sure you want to delete this item?",
        comments: "Comments",
        addComment: "Add comment",
        assigntoCSO: "Assign to CSO",
        archive: "Archive",
        archived: "Archived",
        archivedPlural: "Archived",
        assigned: "Assigned",
        assignedPlural: "Assigned",
        pending: "Pending",
        archiveReason: "Please enter the reason for archiving this incident.",
        archiveReasonExample: "e.g. duplicate, not relevant, etc.",
        assignIntro: "Select an organisation to assign this incident to.",
        assignEmail:"An email will be sent to the organisation to inform them of the assignment.",
        alreadyAssigned: "This incident is already assigned to an organisation.",
        pleaseSelect: "Please select",
        close: "Close",
        loginFailed: "Login failed, please try again",
        hello: "Hello",
        changePassword: "Change password",
        passwordsDontMatch: "Your Passwords don't match",
        firstLogin: "This is your first login or you recently requested a password reset.",
        pleaseChangePassword: "Please change your password.",
        newPassword: "New password",
        confirmPassword: "Confirm password",
        comingSoon: "Coming soon",
        accountIsDisabled: "Your account is disabled",
        accept: "Accept",
        accepted: "Accepted",
        reject: "Reject",
        rejected: "Rejected",
        rejectReason: "Please enter the reason for rejecting this incident.",
        reason: "Reason",
        forgotPassword: "Forgot your password?",
        enterEmail: "Please enter your email address",
        pleaseEnterValidEmail: "Please enter a valid email address",
        enterEmailThankYou: "Thank you. Please check your email for further instructions.",
        continue: "Continue",
        infoExport:"The exported file will contain all incidents that match the current filter.",
        province: "Province",
        territory: "Territory",
        category: "Category",
        subCategory: "Sub-category",
        subcategory: "Sub-category",
        informationAfterFollowUp: "Information after follow-up",
        status: "Status",
        status_update: "Status - Update",
        lastModified: "Last modified",
        chiefdomSector: "Chiefdom/Sector or Community",
        groupQuarter: "Group or Quarter",
        isIncidentCLS:"Is the incident followed by sub-CLS, CLS and/or CPS",
        miningSite:"Mining site or Village",
        pcode:"Pcode",
        lattitude:"Lattitude",
        longitude:"Longitude",
        source:"Source",
        localisation:"Localisation",
        owner:"Owner",
        admin:"Admin",
        user: "User",
        errorGeneric: "An error occurred, please try again",
        next: "Next",
        previous: "Previous",
        syncing: "Syncing",
        allDataSynced: "All data synced",
        noNetwork: "No network connection",
        eventsSinceYourLastLogin: "events since your last login",
        showMore: "Show more",
        showLess: "Show less",
        clear: "Clear",
        incidentsSMS: "SMS Incidents",
        incidentsCSO: "CSO Incidents",
        organisations: "Organisations",
        help: "Help",
        home: "Home",
        nonAssigned: "Non Assigned",
        open: "Open",
        completed: "Completed",
        myIncidents: "My incidents",
        allIncidents: "All incidents",
        allIncidentsCSO: "All incidents CSO",
        assignedTo: "Assigned to ",
        recentActivities: "Recent activities",
        to: "to",
        date: "Date",
        feedback: "Feedback",
        information: "Information",
        description: "Description",
        title: "Title",
        coordinates: "Coordinates",
        commented: "Commented on",
        urgency: "Urgency",
        violence: "Violence",
        violence_who: "Violence - Who",
        violence_number: "Violence - Number",
        lead_organization: "Lead Organization",
        roadblock: "Roadblock",
        language: "Language",
        who: "Who",
        mining: "Mining",
        mining_number: "Mining - Number",
        child_labor: "Child labor",
        child_labor_number: "Child labor - Number",
        child_labor_other: "Child labor - Other",
        environmental_issue:"Environmental issue",
        violence_theft:"Violence or theft",
        accident:"Accident",
        corruption_fraud : "Corruption or fraud",
        industrial_conflict: "Industrial conflict",
        conflict_with_industrial_company: "Conflict with industrial company",
        mercury_use: "Mercury use",
        water_pollution: "Water pollution",
        air_pollution: "Air pollution",
        soil_pollution: "Soil pollution",
        deforestation: "Deforestation",
        poaching: "Poaching",
        other:"Other",
        abuse_of_power:"Abuse of power",
        illegal_taxation:"Illegal taxation",
        bribery:"Bribery",
        non_declaration_of_production:"Non-declaration of production",
        mixing_origin_of_minerals:"Mixing origin of minerals",
        selling_of_itsci_tags:"Selling of iTSCi tags",
        torture_degrading_treatment:"Torture, degrading treatment",
        forced_labour:"Forced labour",
        kidnapping:"Kidnapping",
        theft_and_pillage:"Theft and pillage",
        armed_attack: "Armed attack",
        sexual_violence:"Sexual violence",
        pit_collapse:"Pit collapse",
        injury_of_miners:"Injury of miners",
        traffic_accident:"Traffic accident",
        equipment_failure:"Equipment failure",
        use_of_explosives:"Use of explosives",
        flooding:"Flooding",
        dont_know:"Dont know",
        surface_digging:"Surface digging",
        crushing_or_panning:"Crushing or panning",
        transporting:"Transporting",
        mercury:"Mercury",
        underground_digging:"Underground digging",
        operating_machines:"Operating machines",
        fill_in_required_fields:"Please fill in all required fields",
        theme:"Theme",
        dark:"Dark",
        light:"Light",
        mark_as_completed:"Mark as completed",
        sureToComplete:"Are you sure you want to mark this incident as completed?",
        selectCloseStatus:"Please select the final status of this incident.",
        closed: "Closed",
        resolved: "Resolved",
        notResolved: "Not resolved",
        persistent: "Persistent",
        notFollowedUp: "Not followed up",
        followedUp: "In process of follow up",
        reopen: "Reopen",
        sureToReopen:"Are you sure you want to reopen this incident?",
        mark_as_open:"Mark as open",
        reopened: "Reopened",
        complete_data_first:"Please complete and validate all the incident data first",

    }

    let fr = {
        users: "Utilisateurs",
        reports: "Rapports",
        settings: "Paramètres",
        logout: "Déconnexion",
        login: "Connexion",
        password: "Mot de passe",
        username: "Identifiant",
        email: "E-mail",
        fullname: "Nom",
        role: "Rôle",
        organisation: "Organisation",
        active: "Actif",
        initial: "Mot de passe initial",
        save: "Enregistrer",
        cancel: "Annuler",
        add: "Ajouter",
        edit: "Modifier",
        delete: "Supprimer",
        deleted: "Supprimé",
        deleteReason: "Veuillez entrer la raison de la suppression de cet élément.",
        new: "Nouveau",
        newPlural: "Nouveaux",
        search: "Rechercher",
        export: "Exporter",
        import: "Importer",
        noResults: "Aucun résultat pour ce filtre",
        noDataToDisplay: "Pas de données à afficher",
        no: "Non",
        yes: "Oui",
        preferedLanguage: "Langue préférée",
        name: "Nom",
        fullName: "Nom complet",
        shortName: "Abréviation",
        noData: "Pas de données",
        deleteItemQuestion: "Supprimer cet élément ?",
        comments: "Commentaires",
        addComment: "Ajouter un commentaire",
        assigntoCSO: "Assigner à une OSC",
        archive: "Archiver",
        archived: "Archivé",
        archivedPlural: "Archivés",
        assigned: "Assigné",
        assignedPlural: "Assignés",
        pending: "En attente",
        archiveReason: "Veuillez entrer la raison de l'archivage de cet incident.",
        archiveReasonExample: "par exemple, doublon, non fondé, incomplet, etc.",
        assignIntro: "Sélectionnez une organisation à laquelle assigner cet incident.",
        assignEmail:"Un email sera envoyé à l'organisation pour l'informer de l'assignation.",
        alreadyAssigned: "Cet incident est déjà assigné à une organisation.",
        pleaseSelect: "Veuillez sélectionner",
        close: "Fermer",
        loginFailed: "Échec de la connexion, veuillez réessayer",
        hello: "Bonjour",
        changePassword: "Changer le mot de passe",
        passwordsDontMatch: "Vos mots de passe ne correspondent pas",
        firstLogin: "Ceci est votre première connexion ou vous avez récemment demandé une réinitialisation de mot de passe.",
        pleaseChangePassword: "Veuillez changer votre mot de passe s.v.p.",
        newPassword: "Nouveau mot de passe",
        confirmPassword: "Confirmer le mot de passe",
        comingSoon: "Prochainement",
        accountIsDisabled: "Votre compte est désactivé",
        accept: "Accepter",
        accepted: "Accepté",
        reject: "Refuser",
        rejected: "Refusé",
        rejectReason: "Veuillez entrer la raison du rejet de cet incident.",
        reason: "Raison",
        forgotPassword: "Mot de passe oublié ?",
        enterEmail: "Veuillez entrer votre adresse e-mail",
        pleaseEnterValidEmail: "Veuillez entrer une adresse e-mail valide",
        enterEmailThankYou: "Merci. Veuillez consulter votre e-mail pour plus d'instructions.",
        continue: "Continuer",
        infoExport:"Le fichier exporté contiendra tous les incidents qui correspondent au filtre actuel.",
        province: "Province",
        territory: "Territoire ou Ville",
        category: "Catégorie",
        subCategory: "Sous-catégorie",
        subcategory: "Sous-catégorie",
        informationAfterFollowUp: "Information après suivi",
        status: "Statut",
        status_update: "Statut - Mise à jour",
        lastModified: "Mise à jour",
        chiefdomSector: "Chefferie/Secteur ou Commune",
        groupQuarter: "Groupement ou Quartier",
        isIncidentCLS:"Est-il étudié par Sous CLS, CLS et/ou CPS",
        miningSite:"Site minier ou Village",
        pcode:"Pcode",
        lattitude:"Latitude",
        longitude:"Longitude",
        source:"Source",
        localisation:"Localisation",
        owner:"Propriétaire",
        admin:"Administrateur",
        user:"Utilisateur",
        errorGeneric: "Une erreur s'est produite, veuillez réessayer",
        next: "Suivant",
        previous: "Précédent",
        syncing: "Synchronisation",
        allDataSynced: "Toutes les données sont synchronisées",
        noNetwork: "Pas de connexion réseau",
        eventsSinceYourLastLogin: "événements depuis votre dernière connexion",
        showMore: "Afficher plus",
        showLess: "Afficher moins",
        clear: "Effacer",
        incidentsSMS: "Incidents SMS",
        incidentsCSO: "Incidents CSO",
        organisations: "Organisations",
        help: "Aide",
        home: "Accueil",
        nonAssigned: "Non Assignés",
        open: "Ouverts",
        completed: "Complétés",
        myIncidents: "Mes incidents",
        allIncidents: "Tous les incidents",
        allIncidentsCSO: "Tous les incidents CSO",
        assignedTo: "Assignés à ",
        recentActivities: "Activités récentes",
        to: "à",
        date: "Date",
        feedback: "Feedback",
        information: "Information",
        description: "Description",
        title: "Titre",
        coordinates: "Coordonnées",
        commented: "Commenté sur",
        urgency: "Urgence",
        violence: "Violence",
        violence_who: "Violence - Qui",
        violence_number: "Violence - Nombre",
        lead_organization: "Organisation principale",
        roadblock: "Barrière routière",
        language: "Langue",
        who: "Qui",
        mining: "Exploitation minière",
        mining_number: "Exploitation minière - Nombre",
        child_labor: "Travail des enfants",
        child_labor_number: "Travail des enfants - Nombre",
        child_labor_other: "Travail des enfants - Autre",
        environmental_issue: "Environnement",
        violence_theft: "Violence ou vol",
        accident: "Accident",
        corruption_fraud : "Corruption ou fraude",
        industrial_conflict: "Conflit avec compagnie industrielle",
        conflict_with_industrial_company: "Conflit avec compagnie industrielle",
        mercury_use: "Utilisation de mercure",
        water_pollution: "Pollution de l'eau",
        air_pollution: "Pollution de l'air",
        soil_pollution: "Pollution du sol",
        deforestation: "Déforestation",
        poaching: "Braconnage",
        other:"Autre",
        abuse_of_power:"Abus de pouvoir",
        illegal_taxation:"Taxation illégale",
        bribery:"Pot-de-vin",
        non_declaration_of_production:"Non-déclaration de production",
        mixing_origin_of_minerals:"Mélange de minerais",
        selling_of_itsci_tags:"Vente de tags iTSCi",
        torture_degrading_treatment:"Torture, traitement dégradant",
        forced_labour:"Travail forcé",
        kidnapping:"Kidnapping",
        theft_and_pillage:"Vol et pillage",
        armed_attack:"Agression armée",
        sexual_violence:"Violence sexuelle",
        pit_collapse:"Effondrement de puits",
        injury_of_miners:"Blessure de mineurs",
        traffic_accident:"Accident routier",
        equipment_failure:"Défaillance d'équipement",
        use_of_explosives:"Utilisation d'explosifs",
        flooding:"Inondation",
        dont_know:"Ne sais pas",
        surface_digging:"Creuseur de surface",
        crushing_or_panning:"Broyage ou lavage",
        transporting:"Transport",
        mercury:"Mercure",
        underground_digging:"Creuseur de sous-sol",
        operating_machines:"Conduite de machines",
        fill_in_required_fields:"Veuillez remplir tous les champs obligatoires",
        theme:"Thème",
        dark:"Foncé",
        light:"Clair",
        mark_as_completed:"Marquer comme complété",
        sureToComplete:"Êtes-vous sûr de vouloir marquer cet incident comme complété ?",
        selectCloseStatus:"Veuillez sélectionner le statut final de cet incident.",
        closed: "Fermé",
        resolved: "Résolu",
        notResolved: "Non résolu",
        persistent: "Persistant",
        notFollowedUp: "Non suivi",
        followedUp: "En cours de suivi",
        reopen: "Réouvrir",
        sureToReopen:"Êtes-vous sûr de vouloir réouvrir cet incident ?",
        mark_as_open:"Marquer comme ouvert",
        reopened: "Réouvert",
        complete_data_first:"Veuillez d'abord compléter et valider toutes les données de l'incident",
    }

    let storedLanguage = localStorage.getItem("language") || "fr";
    //console.error(storedLanguage);
    if (storedLanguage==="en") return en;
    return fr;
})();
export default Translations;