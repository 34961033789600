import $ from "./util/dom.js";
import touch from "./util/touch.js";
import nav from "./components/nav.js";
import page from "./views/page.js";
import login from "./views/login.js";
import user from "./user.js";
import Settings from "./settings.js";
import Api from "./api.js";

let container;
async function setupUI() {
    touch.init();

    container = $("main");
    document.body.appendChild(container);
    let theme = Settings.getTheme();
    if (theme==="light") document.body.classList.add("light");


    let isLoggedIn = await user.isLoggedIn();
    if (isLoggedIn){
        home();
    }else{
        login.render(container).then(()=>{
            user.isLoggedIn().then( isLoggedIn=>{
                if (isLoggedIn){
                    home();
                }else{
                    window.location.reload();
                }
            })
        });
    }

    if (Api.isDev()){
        document.body.appendChild($(".devmode","DEVELOPMENT ENVIRONMENT"));
    }

}

function home(){
    nav.render(container);
    nav.updateStats(true);
    user.renderUserMenu(container);
    let hash = window.location.hash;
    if (hash){
        hash = hash.substr(1);
        page.load(hash,container);
    }else{
        page.load("home",container);
    }

}



document.addEventListener("DOMContentLoaded", function(event) {
    setupUI();
});