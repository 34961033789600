import $ from "../util/dom.js";
import Chart from "../components/chart.js";
import api from "../api.js";
import user from "../user.js";
import translations from "../translations.js";
import page from "./page.js";
import {formatDate} from "../util.js";

let Home = (()=>{
    let me = {};

    me.render=async container=>{
        container.innerHTML += "";

        let chartContainer,logContainer;

        container.appendChild($(".home",
            $(".dashboard",
                chartContainer = $(".chartContainer"),
                $(".logContainer",
                    $(".title",translations.recentActivities),
                    logContainer = $(".logs")
                )
            ),
            $(".logo"),
            )
        )

        let userData = user.getCurrentUser();
        let orgId = userData?userData.organisation:"";
        let stats = await api.getStats(orgId,true);
        console.log(stats);

        if (user.isAdmin()){
            let rawChart = {
                title:"Incidents SMS",
                items:[
                    {label:translations.nonAssigned,value:stats.nonassigned,color:"#b78938",action:()=>page.load("incidents-sms/non-assignes")},
                    {label:translations.assigned,value:stats.assigned,color:"#8cce71",action:()=>page.load("incidents-sms/assignes")},
                    {label:translations.archived,value:stats.archived,color:"#51869d",action:()=>page.load("incidents-sms/archives")},
                ]
            }

            Chart.render(chartContainer,rawChart);

        }

        let mycsoChart = {
            title:translations.myIncidents,
            items:[
                {label:translations.new,value:stats.new,color:"#b78938",action:()=>page.load("mes-incidents/nouveaux")},
                {label:translations.active,value:stats.working,color:"#8cce71",action:()=>page.load("mes-incidents/ouvert")},
                {label:translations.completed,value:stats.complete,color:"#51869d",action:()=>page.load("mes-incidents/completes")},
            ]
        }

        Chart.render(chartContainer,mycsoChart);

        let csoChart = {
            title:translations.allIncidents + " CSO",
            items:[
                {label:translations.new,value:stats.pending,color:"#b78938",action:()=>page.load("incidents-cso/nouveaux")},
                {label:translations.open,value:stats.open,color:"#5887af",action:()=>page.load("incidents-cso/ouvert")},
                {label:translations.completed,value:stats.closed,color:"#8cce71",action:()=>page.load("incidents-cso/completes")},
            ]
        }

        Chart.render(chartContainer,csoChart);


        let actionMap={
            "create":"created",
            "update":"updated",
            "delete":"deleted",
            "login":"logged in",
            "logout":"logged out",
            "assign":"assigned",
            "unassign":"unassigned",
            "accept":"accepted",
            "unaccept":"unaccepted",
            "resolve":"resolved",
            "unresolve":"unresolved",
            "archive":"archived",
            "unarchive":"unarchived",
            "close":"closed",
            "reopen":"reopened",
            "comment":"commented",
            "password":"changed password",
        }
        api.getLogs().then(async logs=>{
            let users = await api.list("users");
            let organisations = await api.list("organisations");

            console.log(logs);
            logs.forEach(log=>{
                let userName = "";
                if (log.user){
                    let user = api.resolve("users",log.user);
                    if (user) userName = user.username;
                }
                let action = actionMap[log.type] || log.type;
                action = userName + " " + translations[action];
                if (log.incident){
                    let displayId = log.incident;
                    if (typeof displayId === "string" && displayId.indexOf("-")>0){
                        displayId = displayId.split("-")[0];
                    }
                    action += " incident " + displayId;
                }
                if (log.organisation){
                    let organisation = api.resolve("organisations",log.organisation);
                    if (organisation){
                        action += " " + translations.to + " " + organisation.name;
                    }

                }
                $(".logitem",{parent:logContainer,onClick:()=>{
                    if (log.incident){
                        page.load("incidents-cso/" + log.incident);
                    }}},
                    $(".logdate",formatDate(log.lastModified)),
                    $(".logtext",action)
                )
            })

        })

    }

    return me;
})();
export default  Home;