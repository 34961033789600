import masterDetail from "../components/masterDetail.js";
import dataStructure from "../data.js";

let Organisations = (()=>{
    let me = {};

    me.render = async container=>{
        await masterDetail.render(container,dataStructure.organisations);
    }

    return me;
})();

export default  Organisations;