import user from "../user.js";
import api from "../api.js";
import translations from "../translations.js";
import logger from "../logger.js";

let IncidentService = (()=>{
    let me = {};

    me.postComment = (comment,incident,isAuto)=>{
        return new Promise((next)=>{
            let data ={
                comment:comment,
                incident:incident.report_id || incident.id,
                user:user.getUserId(),
                date:new Date().getTime()
            }
            if (!isAuto){
                logger.log("comment",incident.report_id || incident.id);
            }
            api.update("comments",data).then(next);
        });
    }


    me.assignToCSO = async(incident,organisation)=>{
        let incidentID = incident.report_id || incident.id;

        let data ={
            id:incident.info_id,
            incident:incidentID,
            assigned:organisation,
            assignedBy:user.getUserId(),
            assignedOn:new Date().getTime(),
            status:"Assigné"
        }
        let result = await api.update("incidents_info",data);
        let orgName = api.resolve("organisations",organisation).name;
        api.clearCache("incidents_assigned");
        await me.postComment(translations.assignedTo + " " + orgName,incident,true);
        logger.log("assign",incidentID,organisation);
        return result;
    }


    me.archive = async (incident,status,reason)=>{
        let incidentID = incident.report_id || incident.id;

        let data ={
            id:incident.info_id,
            incident:incidentID,
            archived:!!status,
            archivedBy:user.getUserId(),
            archivedOn:new Date().getTime(),
            archivedReason:reason,
            status:translations.archived
        }
        await api.update("incidents_info",data);
        await me.postComment(translations.archived + ": " + reason,incident,true);
        logger.log("archive",incidentID);
    }

    me.reject = async(incident,reason)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            assigned:false,
            rejected:true,
            accepted:false,
            closed: false,
            rejectedBy:user.getUserId(),
            rejectedOn:new Date().getTime(),
            rejectReason:reason,
            status:translations.rejected
        }
        await api.update("incidents_info",data);
        await me.postComment(translations.rejected + ": " + reason,incident,true);
        logger.log("reject",incidentID);
    }

    me.accept = async (incident)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            accepted:true,
            acceptedBy:user.getUserId(),
            acceptedOn:new Date().getTime(),
            rejected: false,
            closed: false,
            status:"En cours de suivi"
        }
        //console.log(data);
        await api.update("incidents_info",data);
        await me.postComment(translations.accepted,incident,true);
        logger.log("accept",incidentID);

    }

    me.close = async (incident,status)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            closed:true,
            closedBy:user.getUserId(),
            closedOn:new Date().getTime(),
            status:status
        }
        await api.update("incidents_info",data);
        await me.postComment(translations.closed,incident,true);
        logger.log("close",incidentID);
    }

    me.reopen = async (incident)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            closed:false,
            closedBy:"",
            closedOn:"",
            status:"En cours de suivi"
        }
        await api.update("incidents_info",data);
        await me.postComment(translations.reopened,incident,true);
        logger.log("reopen",incidentID);
    }

    me.delete = async (incident)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            deleted:true,
            deletedBy:user.getUserId(),
            deletedOn:new Date().getTime()
        }
        await api.update("incidents_info",data);
        await api.delete("incidents",incidentID);
        await me.postComment(translations.deleted,incident,true);
        logger.log("delete",incidentID);
    }

    me.mergeInfoWithOriginal = async (incident)=>{
        if (incident.id && typeof incident.id === "string" && incident.id.indexOf("-")>0){
            await api.getById("incidents",incident.id).then(result=>{
                if (result){
                    let newKeys = Object.keys(result);
                    let oldKeys = Object.keys(incident);
                    newKeys.forEach(key=>{
                        if (!oldKeys.includes(key)){
                            incident[key] = result[key];
                        }
                    });
                }
            });
        }
    }

    me.isAssigned = (incident)=>{
        return incident.status === translations.assigned;
    }

    me.isInprogress = (incident)=>{
        return incident.status === translations.followedUp;
    }

    me.isClosed = (incident)=>{
        return !!incident.closed;
    }

    me.isComplete = async (incidentInfo)=>{
        //console.error(incidentInfo);

        if (incidentInfo.incident && typeof incidentInfo.incident === "string" && incidentInfo.incident.indexOf("-")>0){
            await api.getById("incidents",incidentInfo.incident).then(result=>{
                if (result){
                    let newKeys = Object.keys(result);
                    let oldKeys = Object.keys(incidentInfo);
                    newKeys.forEach(key=>{
                        if (!oldKeys.includes(key)){
                            incidentInfo[key] = result[key];
                        }
                    });
                }
            });

        }
        let requiredFields = [
            "date","title","description","category","subcategory",
            "province","territoire","sector","group"
        ];
        let complete = true;
        requiredFields.forEach(field=>{
            if (!incidentInfo[field]) complete = false;
        });
        return complete;
    }

    return me;
})();

export default IncidentService;