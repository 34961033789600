import api from './api.js';
import user from './user.js';

let Logger = (()=>{
    let me = {};

   me.log=(type,incident,organisation)=>{
        api.update("logs",{
            user:user.getUserId(),
            incident:incident,
            organisation:organisation,
            type:type
        })
   }

   return me;
})();

export default Logger;