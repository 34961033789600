import $ from "../util/dom.js"
import home from "./home.js"
import users from "./users.js";
import incidents from "./incidents-sms.js";
import assigned from "./incidents-assigned.js";
import nav from "../components/nav.js";
import organisations from "./organisations.js";
import information from "./information.js";
import translations from "../translations.js";
import {isUUID} from "../util.js";
import Settings from "../settings.js";

let Page = (()=>{
    let me = {};
    let pageContainer = null;
    let titleBar = null;
    let spinner = null;

    let colors={
        orange:"#b78938",
        green:"#8cce71",
        blue:"#51869d"
    }
    if(Settings.getTheme() === "light"){
        colors={
            orange:"#f5aa43",
            green:"#8ef663",
            blue:"#1dc3ff"
        }
    }

    let pages = {
        "home":{title:"home",render:home.render},
        "utilisateurs":{title:"Utilisateurs",render:users.render},
        "incidents-sms":{title:"Incidents SMS",render:incidents.render},
        "incidents-sms/non-assignes":{title:"Incidents SMS - Non Assignés",render:incidents.render,params:{filter:"nonassigned"},color:colors.orange},
        "incidents-sms/assignes":{title:"Incidents SMS - Assignés",render:incidents.render,params:{filter:"assigned"},color:colors.green},
        "incidents-sms/archives":{title:"Incidents SMS - Archivés",render:incidents.render,params:{filter:"archived"},color:colors.blue},
        "organisations":{title:"Organisations",render:organisations.render},
        "information":{title:"information",render:information.render},
        "incidents-cso":{title:"Incidents CSO",render:assigned.render},
        "mes-incidents/nouveaux":{title:"Mes Incidents - Nouveaux",render:assigned.render,params:{filter:"new"},color:colors.orange},
        "mes-incidents/ouvert":{title:"Mes Incidents - Ouverts",render:assigned.render,params:{filter:"working"},color:colors.green},
        "mes-incidents/completes":{title:"Mes Incidents - Complétés",render:assigned.render,params:{filter:"complete"},color:colors.blue},
        "incidents-cso/nouveaux":{title:"Tous les Incidents CSO - Nouveaux",render:assigned.render,params:{filter:"pending"},color:colors.orange},
        "incidents-cso/ouvert":{title:"Tous les Incidents CSO - Ouverts",render:assigned.render,params:{filter:"open"},color:colors.green},
        "incidents-cso/completes":{title:"Tous les Incidents CSO - Complétés",render:assigned.render,params:{filter:"closed"},color:colors.blue},
    }

    me.load=(pageName,container)=>{
        console.log("Loading page " + pageName);
        let id;
        if (pageName.indexOf("/")>0){
            let parts = pageName.split("/");
            let p = parts.pop();
            if (isNaN(p)){
                if (isUUID(p)){
                    id = p;
                    pageName = parts.join("/");
                }else{
                    id=undefined;
                }
            }else{
                id=p;
                pageName = parts.join("/");
            }
        }

        if (!container) container = document.body.querySelector("main");
        if (!pageContainer){
            pageContainer = $("div.page",{parent:container});
        }
        pageContainer.innerHTML = "";

        let page = pages[pageName];
        if (page){
            //history.pushState({page:pageName},page.title,pageName);
            window.location.hash = pageName;
            let title =  translations[page.title] || page.title || pageName;
            document.title = "Kufatilia - " + title;
            titleBar = $(".titlebar",{parent:pageContainer},spinner=$("span"),page.color?$(".dot",{style:{backgroundColor:page.color}}):null,title);
            let pageContent = $(".pagecontent",{parent:pageContainer});
            if (page.render) page.render(pageContent,page.params,id);
            nav.sync(pageName);
        }
    }

    me.waiting = state=>{
        if (titleBar) titleBar.classList.toggle("loading",state);
        if (state){
            if (spinner) spinner.innerHTML = "<div class='spinner'></div>";
        }else{
            if (spinner) spinner.innerHTML = "";
        }

    }

    return me;
})();
export default  Page;