import $ from "../util/dom.js";
import Translations from "../translations.js";

let Information = (()=>{
    let me = {};

    me.render=async container=>{
        let content = await fetch("_pages/information.html").then(r=>r.text());
        container.innerHTML = content;
    }

    return me;
})();
export default  Information;