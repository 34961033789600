import masterDetail from "../components/masterDetail.js";
import dataStructure from "../data.js";
import api from "../api.js";
import $ from "../util/dom.js";
import translations from "../translations.js";
import {formatDate,cleanString} from "../util.js";
import incidentService from "../services/incidentService.js";
import nav from "../components/nav.js";


let IncidentsSms = (()=>{
    let me = {};

    let labels={
        platform_category:"Category",
        report_date:"Date",
        platform_status:"Status",
        platform_status_update:"Status update",
        report_language:"Language",
        report_facility:"Province",
        report_mining:"Mining",
        report_mining_numb:"Mining number",
        report_corruption:"Corruption",
        platform_urgency:"Urgency",
        report_who:"Who",
        report_violence:"Violence",
        report_violence_who:"Violence who",
        report_violence_numb:"Violence number",
        report_environment:"Environment",
        report_environment_who:"Environment who",
        lead_organization:"Lead organization",
        report_childlabor:"Child labor",
        report_childlabor_other:"Child labor other",
        report_childlabor_numb:"Child labor number",
        report_roadblock:"Roadblock",
        report_roadblok:"Roadblock",
    };

    let ignoreKeys=[
        "id",
        "report_id",
        "report_informant",
        "platform_title",
        "platform_description",
        "platform_location",
        "report_location",
        "platform_location_latitude",
        "platform_location_longitude",
        "report_category",
        "report_describ",
        "info_id"
    ];

    let translateKeys=[
        "platform_category",
        "report_environment"
    ];


    let resolveKeys={
        assigned: async (value)=>{let item = await api.getById("organisations",value); return item?item.name:""},
    }

    me.render = async (container,params,id)=>{
        let config = dataStructure.incidentsSMS;
        config.openDetailId = id;

        if (params && params.filter){
            config.preFilterName = params.filter;
            if (params.filter==="assigned"){
                config.preFilter = item=>!!item.assigned && !item.archived
            }

            if (params.filter==="nonassigned"){
                config.preFilter = item=>!item.assigned && !item.archived;
            }

            if (params.filter==="archived"){
                config.preFilter = item=>!!item.archived;
            }

        }

        config.detail= async (item,formContainer)=>{

            window.location.hash = "incidents-sms/" + item.report_id || item.id;


            formContainer.innerHTML = "<h3>Incident</h3>";
            formContainer.appendChild($(".closebutton.light",{onClick:masterDetail.closeSidePanel}));
            let mapView,coElm;

            let data = await api.getById("incidents_external",(item.report_id || item.id),true);
            let detailElm = $(".detail",
                {parent:formContainer},
                data.platform_title?$("h4",data.platform_title):undefined,
                mapView = $("#mapView",$(".spinner")),
                coElm = $(".coordinates")
                );
            let comments = await getComments(data);

            mapView.innerHTML = ""


            let lat = data.platform_location_latitude;
            let lng = data.platform_location_longitude;
            lat = parseFloat(lat);
            lng = parseFloat(lng);

            if (lng && lat){
                coElm.innerHTML = lat + ", " + lng;
                showMap(mapView,lat,lng,true,coElm);
            }

            let commentForm,commentlist
            $("div",{parent:detailElm},
                $(".location",data.platform_location),
                $("h4",translations.information),
                $("table",Object.keys(data).map(key=>{
                    if (ignoreKeys.indexOf(key)>=0) return;
                    if (key.startsWith("platform_comment_")) return;
                    let value = data[key];

                    if (key==="report_date") value = formatDate(value);
                    let td = $("td",""+value);

                    if (resolveKeys[key]){
                        value = resolveKeys[key](value);
                        if (value && value.then){
                            value.then(value=>{
                                td.innerText = value;
                            });
                        }else{
                            td.innerText = value;
                        }
                    }

                    if (translateKeys.indexOf(key)>=0){
                        let translated = translations[cleanString(value)];
                        if (translated) td.innerText = translated;
                    }


                    if (value){
                        let label = labels[key] || key;
                        let translated = translations[label.toLowerCase().split(" ").join("_")];
                        label = translated || label;
                        return $("tr",$("td",""+label),td);
                    }
                })),
                $("h4",translations.description),
                $(".description",data.report_describ),
                $("h4",translations.comments),
                commentlist = $(".commentlist", renderComments(comments)),
                $("button.text.add.addcomment" + (comments.length?"":".up"),{onClick:()=>{
                        commentForm.style.display = "block";
                        setTimeout(()=>{
                            let textarea = commentForm.querySelector("textarea");
                            textarea.focus();
                            textarea.scrollIntoView({ behavior: "smooth"})
                        },10);
                    }},translations.addComment),
                commentForm = $("div.commentform",$("textarea"),
                    $("button.secondary.green.arrow",{onClick:()=>{
                            let comment = commentForm.querySelector("textarea").value;
                            if (comment){
                                commentlist.innerHTML = "";
                                commentlist.appendChild($(".spinner"));
                                incidentService.postComment(comment,item).then(()=>{
                                    getComments(data).then(comments=>{
                                        commentlist.innerHTML = "";
                                        commentlist.appendChild($("div",renderComments(comments)));
                                    });
                                });

                            }
                            commentForm.style.display = "none";
                        }},translations.save),
                    $("button.secondary.red.delete",{onClick:()=>{
                            commentForm.style.display = "none";
                        }},translations.cancel)
                    )
            )

            let actionPanel = $(".incidentactions",
                {parent:formContainer},
                $(".slidepanel"),
                $(".buttonsbar",
                    $("button.primary.green.arrow",
                        {onClick:()=>{
                                showAssignForm(actionPanel,item);
                        }},
                        translations.assigntoCSO),
                    (!item.archived && $("button.primary.red.delete",
                        {onClick:()=>{
                                showArchiveForm(actionPanel,item);
                            }},
                        translations.archive)),
                    $("button.primary.blue.arrow",
                        {onClick:()=>{
                                masterDetail.closeSidePanel();
                            }},
                        translations.cancel)
                )
            );
        };

        // preload organisations
        await api.list("organisations");
        await api.list("users");
        await masterDetail.render(container,config);

    }

    function renderComments(comments){
        return comments.map(comment=>$(".commentbox",$(".author",comment.user),$(".comment",comment.comment),$(".date",comment.date)));
    }

    function showArchiveForm(parent,item){
        let slidePanel = parent.querySelector(".slidepanel");
        let form = $(".actionform",
            {parent:slidePanel},
            $("h3",translations.archive),
            $(".content",
                $("p",translations.archiveReason),
                $("div", $("textarea",{placeholder:translations.archiveReasonExample})),
                $("button.secondary.blue.arrow",{
                    onClick:()=>{
                        let textarea = form.querySelector("textarea");
                        let reason = textarea.value;
                        if (reason){
                            let from = "nonassigned";
                            if (item.assigned) from = "assigned";
                           incidentService.archive(item,true,reason).then(()=>{
                                masterDetail.refresh();
                                closeForm();
                                nav.animateUpdate(from,"archived",1);
                            });
                        }else{
                            textarea.classList.remove("error");
                            setTimeout(()=>{
                                textarea.classList.add("error");
                            },10);
                        }
                    }
                },translations.archive),
                $("button.secondary.red.delete",
                    {onClick:closeForm},
                    translations.cancel)
            )
        );
        parent.classList.add("active");
        setTimeout(()=>{
            form.classList.add("active");
        },10);

        function closeForm(){
            form.classList.remove("active");
            setTimeout(()=>{
                parent.classList.remove("active");
                slidePanel.removeChild(form);
            },300);
        }
    }

    async function showAssignForm(parent,item){
        let slidePanel = parent.querySelector(".slidepanel");
        let selectPanel;
        let form = $(".actionform",
            {parent:slidePanel},
            $("h3",translations.assigntoCSO),
            $(".content",
                item.assigned?$(".warningbox",translations.alreadyAssigned):undefined,
                $("p",translations.assignIntro + " " + translations.assignEmail),
                selectPanel = $("div",$(".spinner")),
                $("button.secondary.blue.arrow",{
                    onClick:()=>{
                        let select = selectPanel.querySelector("select");
                        let organisation = select.value;
                        if (organisation){
                            let from = "nonassigned";
                            if (item.assigned) from = "assigned";
                            incidentService.assignToCSO(item,organisation).then(()=>{
                                masterDetail.refresh();
                                closeForm();
                                nav.animateUpdate(from,"assigned",1);
                            });
                        }else{
                            select.classList.remove("error");
                            setTimeout(()=>{
                                select.classList.add("error");
                            },10);
                        }
                    }

                },translations.assigntoCSO),
                $("button.secondary.red.delete",
                    {onClick:closeForm},
                    translations.cancel)
            )
        );
        parent.classList.add("active");
        setTimeout(()=>{
            form.classList.add("active");
        },10);

        function closeForm(){
            form.classList.remove("active");
            setTimeout(()=>{
                parent.classList.remove("active");
                slidePanel.removeChild(form);
            },300);
        }


        let list = await api.distinct("organisations","name");
        selectPanel.innerHTML = "";
        $("select",{parent:selectPanel},
            $("option",{value:""},"--- " + translations.pleaseSelect),list.map(item=>$("option",{value:item.id},item.name)));
    }

    function showMap(parent,lat,lng,dragMarker,coElm){
        mapboxgl.accessToken = 'pk.eyJ1IjoiaXBpc3Jlc2VhcmNoIiwiYSI6IklBazVQTWcifQ.K13FKWN_xlKPJFj9XjkmbQ';
        const map = new mapboxgl.Map({
            container: 'mapView', // container ID
            style: 'mapbox://styles/mapbox/streets-v12', // style URL
            center: [lng, lat], // starting position [lng, lat]
            zoom: 9 // starting zoom
        });

        const marker = new mapboxgl.Marker({draggable: dragMarker})
            .setLngLat([lng, lat])
            .addTo(map);

        marker.on('dragend', ()=>{
            const co = marker.getLngLat();
            coElm.innerHTML = co.lat.toFixed(7) + ", " + co.lng.toFixed(7);
            //coordinates.style.display = 'block';
            //coordinates.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;
        });


    }

    async function getComments(data){
        let comments=[];
        for (let i=1;i<=10;i++){
            let comment = data["platform_comment_"+i];
            if (comment){
                let c={}
                let p = comment.indexOf(" added ");
                if (p>=0){
                    c.user=comment.substring(0,p);
                    comment = comment.substring(p+7);
                }
                p = comment.lastIndexOf(" at ");
                if (p>=0){
                    c.date=comment.substring(p+4);
                    comment = comment.substring(0,p);
                    let d = new Date(c.date);
                    if (isDateValid(d)) c.date = formatDate(d);
                }
                c.comment=comment;
                comments.push(c);
            }
        }

        let k_Comments = await(api.list("comments",{incident:data.report_id}));
        k_Comments.forEach(comment=>{
            if (typeof comment.date === "number") comment.date = formatDate(comment.date);
            if (comment.user && comment.user.length>8){
                let user = api.resolve("users",comment.user);
                if (user) comment.user = user.fullname;
            }
            comments.push(comment);
        });

        comments.sort((a,b)=>a.date>b.date?-1:1);
        return comments;
    }

    function isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
    }

    return me;
})();
export default IncidentsSms;