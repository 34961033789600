export default function dom(tagName,options){
    let elm;
    let opt = {};
    let index = 1;
    let hasParent = false;
    if (typeof options === "object" && !Array.isArray(options) && !(options instanceof Element)){
        opt = options;
        index++;
    }

    if (tagName instanceof Element){
        elm = tagName;
    }else{
        // allow tag.class and tag#id constructors
        if (tagName.indexOf(".")>=0){
            let classNames = tagName.split(".");
            tagName = classNames.shift();
            opt.className = ((opt.className || "") + " " +  classNames.join(" ")).trim();
        }
        if (tagName.indexOf("#")>=0){
            let p = tagName.split("#");
            tagName = p.shift();
            opt.id = p[0];
        }
        tagName = tagName||"div";
        elm = document.createElement(tagName);
    }


    for (let key in opt) {
        if (key === 'parent'){
            opt[key].appendChild(elm);
            hasParent = true;
            continue;
        }

        if (key === 'style' && typeof opt[key] === 'object'){
            for (let styleKey in opt[key]){
                elm.style[styleKey] = opt[key][styleKey];
            }
            continue;
        }


        if (key === 'onClick' || key === 'onDoubleClick' || key === 'onDrag' || key === 'onDown') addClass(elm,opt,"handle");
        if (key === 'onDragStart'){
            addClass(elm,opt,"draggable");
            if (opt.globalDrag) addClass(elm,opt,"global");
        }
        if (key === 'onDrop') addClass(elm,opt,"droptarget");
        if (key === 'onContext') addClass(elm,opt,"context");
        if (key === 'info') addClass(elm,opt,"info");
        if (key === 'key'){
            elm.setAttribute("data-i18",opt[key]);
            continue;
        }

        elm[key] = opt[key];
    }

    for (; index < arguments.length; index++) {
        append(elm, arguments[index]);
    }

    return elm;
}



let append = (parent, child) => {
    if (child) {
        if (Array.isArray(child)) {
            child.map(sub => append(parent, sub));
        } else {
            if (typeof child === "string" || typeof child === "number") child = document.createTextNode(child);
            parent.appendChild(child);
        }
    }
};

let addClass=(elm,opt,className)=>{
    elm.classList.add(className);
    if (opt.className) opt.className += " " + className;
}
